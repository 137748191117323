@charset "UTF-8";
.btn-start-lc {
  background: #60be5b;
  border: 0px;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  width: 100%;
  text-transform: uppercase;
  border-radius: 4px;
  color: #fff;
}
.btn-start-lc:hover {
  background: shade-color(#60be5b, 10%);
}

button#startchat-button:disabled {
  background: #dcdadb;
}

.cta-icons {
  display: flex;
  align-items: center;
}

.chat-area {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  width: 100%;
}

.chat-wrapper:focus-within {
  border: 1.4px solid #4EB246;
}

.chat-wrapper {
  border: 1.4px solid #E8E8E8;
  margin-bottom: 20px;
  margin-top: 20px;
}

#reconnect {
  display: none;
  opacity: 0.6;
  bottom: 0;
  color: #FFF;
  font-size: 2.5rem;
  height: 100%;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 99;
  background: #000;
}

#chat-modal-header {
  height: 50px;
  width: 100%;
  background-color: #353535;
  box-sizing: border-box;
  padding: 10px;
}

#chat-modal {
  background-color: #eee;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#chat-modal-content {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: calc(100% - 120px);
}

#chat-modal-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}

#chat-modal-content-chattext {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}

#chat-modal-content-input {
  width: 100%;
  box-sizing: border-box;
  border: 0;
  border-top: 1px solid #ccc;
  height: 60px;
  font-size: 16px;
  line-height: 21px;
  font-family: inherit;
  padding: 10px;
  padding-right: 80px;
}

#chat-modal-send-button {
  right: 0;
  top: 0;
  width: 80px;
  height: 60px;
  -webkit-appearance: none;
  border: 0;
  border-radius: 0 5px 5px 0;
  background-color: #60be5b;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  font-family: inherit;
  text-transform: none;
  cursor: pointer;
}
#chat-modal-send-button:hover {
  background: #3e8d38;
}

#chat-modal-send-button.disabled {
  cursor: not-allowed;
  background-color: #555;
  color: #999;
}

#email-chat-transcript {
  background-color: #fff;
  border: 1px solid #dededc;
  box-sizing: border-box;
  left: 50%;
  max-width: 95%;
  min-width: 95%;
  padding: 24px;
  position: fixed;
  text-align: center;
  top: 70%;
  transform: translate(-50%, -50%);
  transition: 1s all ease-in-out;
}

#email-chat-transcript > h2 {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  text-transform: uppercase;
}

#email-chat-transcript > h3 {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  margin-bottom: 30px;
  margin-top: 12px;
}

.chat-text-agent,
.chat-text-client {
  background-color: #FCE1D4;
  padding: 3px 10px;
  display: inline-block;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  border-radius: 8px;
  min-width: 41px;
  clear: both;
  position: relative;
  box-sizing: border-box;
}

@media only screen and (min-width: 767px) {
  .chat-text-client {
    max-width: 80%;
  }
}
.chat-text-agent {
  float: left;
  border-bottom-left-radius: 0px;
}

.chat-text-client {
  float: right;
  background-color: #E8E8E8;
  border-bottom-right-radius: 0px;
}

.chat-text-system {
  font-size: 14px;
  padding-bottom: 20px;
  display: block;
  border-bottom: 1px solid #dededc;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  clear: both;
  margin-bottom: 20px;
}

@keyframes typing-1 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #ccc;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes typing-1 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #ccc;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes typing-2 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  75% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes typing-2 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  75% {
    background-color: #fff;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes typing-3 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes typing-3 {
  0% {
    background-color: #fff;
  }
  25% {
    background-color: #fff;
  }
  50% {
    background-color: #fff;
  }
  75% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
}
@keyframes spinny {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-content {
  min-height: 4rem;
  position: relative;
  color: rgba(255, 255, 255, 0);
}
.loading-content:before {
  content: "";
  border: 2px solid #fefefe;
  border-top-color: #ccc;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 1rem);
  top: calc(50% - 1rem);
  width: 2rem;
  height: 2rem;
  display: block;
  box-sizing: border-box;
  animation-name: spinny;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.loading-content:after {
  content: "";
  border: 4px solid #fefefe;
  border-top-color: #ccc;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 1.7rem);
  top: calc(50% - 1.7rem);
  width: 3.4rem;
  height: 3.4rem;
  display: block;
  box-sizing: border-box;
  animation-name: spinny;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

#agent-typing {
  padding: 5px 7px 5px 10px;
  background-color: #fff;
  border: 1px solid #dededc;
  display: inline-block;
  margin-top: 10px;
  border-radius: 5px;
  clear: both;
  float: left;
}

.dot,
.dotdot,
.dotdotdot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  display: inline-block;
  margin-right: 3px;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease-in-out;
}

.dot {
  animation-name: typing-1;
  animation-duration: 2s;
  -webkit-animation-name: typing-1;
  -webkit-animation-duration: 2s;
}

.dotdot {
  animation-name: typing-2;
  animation-duration: 2s;
  -webkit-animation-name: typing-2;
  -webkit-animation-duration: 2s;
}

.dotdotdot {
  animation-name: typing-3;
  animation-duration: 2s;
  -webkit-animation-name: typing-3;
  -webkit-animation-duration: 2s;
}

#chat-modal-close {
  color: #dededc;
  position: absolute;
  top: -5px;
  right: 10px;
  font-size: 40px;
  border-radius: 5px;
}
#chat-modal-close:hover {
  color: #fff;
  cursor: pointer;
}

#chat-ticket-details {
  margin-top: 30px;
}

#chat-ticket-details h2 {
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: 27px;
}

#chat-ticket-details h3 {
  margin: 0;
  margin-top: 12px;
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 18px;
}

/* Rating CSS below */
#chat-feedback-modal {
  background-color: #fff;
  border: 1px solid #dededc;
  max-width: 95%;
  min-width: 95%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 24px;
  text-align: center;
  transition: 1s all ease-in-out;
}

#chat-feedback-modal h2 {
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  line-height: 27px;
}

#chat-feedback-modal h3 {
  margin: 0;
  margin-top: 12px;
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 18px;
}

.rate-link {
  display: inline-block;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: #fff4b4;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border: 1px solid #ccbd68;
}

@media only screen and (min-width: 768px) {
  .rate-link {
    display: inline-block;
    float: none;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.rate-link:hover {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 3.6rem;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 3.6rem;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 0;
  padding: 0;
  height: 3.6rem;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #dededc;
  border-radius: 0;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option .flag::before {
  margin-right: 1rem;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 3.6rem;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 3.6rem;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 3rem;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dededc;
  border-radius: 0;
  cursor: text;
  min-height: 3.6rem;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #dededc;
  border-radius: 0;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid #dededc 1px;
  outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open {
  z-index: 99999999999;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #dededc;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #dededc;
  border-radius: 0;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #dededc;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 3.6rem;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #dededc;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 3.6rem;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #dededc;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #dededc;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #dededc;
  border-radius: 0;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #dededc;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #dededc;
  border-radius: 0;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #dededc;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #dededc;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.loading-content {
  width: 100%;
}

.select2-selection__rendered:empty:before {
  content: " ";
}

.chat-btn {
  background: #0075dd;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-btn span {
  text-transform: none;
}

.offline-form,
.online-form {
  width: 400px;
  margin: 0 auto;
}
.offline-form .card,
.online-form .card {
  border-radius: 15px;
}
.offline-form .gradient-red-org,
.online-form .gradient-red-org {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.offline-form .header-content,
.online-form .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-in-progress {
  max-height: 500px;
  height: 500px;
  overflow-y: scroll;
}
.chat-in-progress .chat-content .agent {
  display: flex;
  align-items: center;
}
.chat-in-progress .chat-content .agent span {
  font-size: 14px;
}
.chat-in-progress .chat-content .user {
  display: flex;
  align-items: center;
}
.chat-in-progress .chat-content .user span {
  font-size: 14px;
}
.chat-in-progress .chat-content .agent p {
  font-size: 14px;
  background: #FCE1D4;
  padding: 10px;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
}
.chat-in-progress .chat-content .user p {
  font-size: 14px;
  background: #E8E8E8;
  padding: 10px;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
}
.chat-in-progress .chat-input {
  width: 100%;
  position: fixed;
  bottom: 0px;
}

.sale-popup {
  position: absolute;
  bottom: 115px;
  right: 10px;
}
.sale-popup .sale-popup-card {
  position: relative;
  border-radius: 6px;
  background: white !important;
  border: 1px solid #ccc;
  padding: 0 15px;
  width: 250px;
}
.sale-popup .sale-popup-card p {
  font-size: 14px !important;
}
.sale-popup .sale-popup-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: -19px;
  width: 50px;
  border-radius: 50%;
  margin-left: 5px;
}
.sale-popup .sale-popup-icon img {
  background: #F7F7F7;
  z-index: 10;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

@media (max-width: 991.98px) {
  .sale-popup {
    display: none;
  }
}